
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';
import store from '@/store';

@Options({})
export default class productSelector extends mixins(WithModel) {

    @Prop() categoryName: string;
    @Prop() items: OM.ProductUrl[];
    @Prop() isFondoTavola: boolean;
    @Prop() selectedProducts: OM.ProductUrl[];
    @Prop() isRiepilogo: boolean;
    @Prop() useWcImage: boolean;

    showAll: boolean = false;
    bodyEl: any;
    postoTavola: number = 0;

    created(){
        this.postoTavola = parseInt(<string>this.$route.params.posizione);

        let daSpostare = this.items.filter(x => this.isSelected(x));
        daSpostare.forEach(x => {
            let index = this.items.findIndex(c => x == c);
            this.items.splice(index, 1);
            this.items.unshift(x);
        })
    }

    mounted(){
        this.bodyEl = this.$refs.body;
    }

    isSelected(item: OM.ProductUrl){
        if(this.isRiepilogo)
            return false;
        return this.selectedProducts && this.selectedProducts.findIndex(x => x.sku == item.sku) > -1 ||
            store.state.tavola.setsPiatti.findIndex(x => x.sku == item.sku) > -1 ||
            store.state.tavola.setsDecorazioni.findIndex(x => x.sku == item.sku) > -1
            ;
    }

    doScroll(amount: number){
        this.bodyEl.scrollLeft = this.bodyEl.scrollLeft + amount;
    }

    onSelect($event){
        if(this.isRiepilogo){
            this.$router.push('/table/productDetail/' + $event.sku);
        } else {
            this.$emit('select', $event)
        }
    }

    onRemove($event){
        this.$emit('remove', $event);
    }

    onDetails($event){
        this.$router.push('/table/productDetail/' + $event.sku);
    }

}
