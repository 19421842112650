
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import store from '@/store';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class cartResume extends Vue {

    @Prop({
        default: []
    }) extraItems: OM.ProductUrl[];

    get itemsCount(){
        let ris = 0;
        ris += store.state.tavola.setsPiatti.length;
        ris += store.state.tavola.setsDecorazioni.length;
        ris += store.state.tavola.extraProducts.length;
        for(let i = 1; i < 10; i++){
            ris += store.state.tavola['posto' + i].products.length;
        }
        return ris;
    }

    get totalAmount(){
        let ris = 0;
        ris += store.state.tavola.setsPiatti.reduce((tot, x) => {
            return tot += x.prezzo;
        }, 0);
        ris += store.state.tavola.setsDecorazioni.reduce((tot, x) => {
            return tot += x.prezzo;
        }, 0);
        ris += store.state.tavola.extraProducts.reduce((tot, x) => {
            return tot += x.prezzo;
        }, 0);
        for(let i = 1; i < 10; i++){
            ris += store.state.tavola['posto' + i].products.reduce((tot, x) => {
                return tot += x.prezzo;
            }, 0);
        }
        return ris;
    }

}
