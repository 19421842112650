
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import { baseUrl } from '@/config';
import WithModel from '@/mixins/withModel';

@Options({})
export default class baseTavola extends mixins(WithModel) {

    @Prop() item: OM.FondoTavola;

    url: string = "";
    created(){
        this.url = baseUrl + this.item.url;
    }

    get imageurl(){
        return "background-image: url('" + this.url + "')";
    }

}
