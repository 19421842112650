import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "productSelectorContainer mt-4" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "ms-1" }
const _hoisted_6 = { class: "right text-gray" }
const _hoisted_7 = { class: "fondoTavolaSpacer" }

export function render(_ctx: any,_cache: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_tovaglietta_emptier = _resolveComponent("tovaglietta-emptier")!
  const _component_tovaglietta = _resolveComponent("tovaglietta")!
  const _component_product_url = _resolveComponent("product-url")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_localized_text, {
            localizedKey: _ctx.categoryName,
            text: _ctx.categoryName
          }, null, 8, ["localizedKey", "text"]),
          _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(_ctx.items.length) + ")", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAll = !_ctx.showAll))
        }, [
          (_ctx.showAll)
            ? (_openBlock(), _createBlock(_component_localized_text, {
                key: 0,
                class: "text-gray",
                localizedKey: "Visualizza meno",
                text: "Visualizza meno"
              }))
            : (_openBlock(), _createBlock(_component_localized_text, {
                key: 1,
                class: "text-gray",
                localizedKey: "Visualizza tutti",
                text: "Visualizza tutti"
              }))
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["productSelector", { showAll: _ctx.showAll }]),
      ref: "body"
    }, [
      (_ctx.isFondoTavola && !_ctx.isRiepilogo)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_tovaglietta_emptier, {
                modelValue: _ctx.localValue,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
                  _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitModel(_ctx.localValue)))
                ]
              }, null, 8, ["modelValue"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "fondoTavolaSpacer",
                key: i
              }, [
                _createVNode(_component_tovaglietta, {
                  item: item,
                  modelValue: _ctx.localValue,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue) = $event)),
                    _cache[4] || (_cache[4] = ($event: any) => (_ctx.emitModel(_ctx.localValue)))
                  ]
                }, null, 8, ["item", "modelValue"])
              ]))
            }), 128))
          ], 64))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "productUrlSpacer",
              key: i
            }, [
              _createVNode(_component_product_url, {
                item: item,
                selected: _ctx.isSelected(item),
                postoTavola: _ctx.postoTavola,
                onSelect: _ctx.onSelect,
                onRemove: _ctx.onRemove,
                useWcImage: _ctx.useWcImage,
                onDetails: _ctx.onDetails
              }, null, 8, ["item", "selected", "postoTavola", "onSelect", "onRemove", "useWcImage", "onDetails"])
            ]))
          }), 128))
    ], 2)
  ]))
}