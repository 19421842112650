
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';

@Options({})
export default class emailModal extends Vue {

    @Prop() sendCb: (email: string) => Promise<void>

    mail: string = "";

    send(){
        this.sendCb(this.mail)
        .then(x => {
            this.$emit('close');
        })
    }

}
