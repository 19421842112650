
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import { baseUrl } from '@/config';

@Options({})
export default class productUrl extends Vue {

    @Prop() item: OM.ProductUrl;
    @Prop() selected: boolean;
    @Prop() postoTavola: number;
    @Prop() useWcImage: boolean;

    url: string = "";
    created(){
        this.url = baseUrl + this.item.url;
    }

    get itemStyle(){
        if(this.postoTavola){
            return 'background-image: url("' + this.getProductUrl(this.item) + '");';
        } else if(this.useWcImage) {
            return 'background-image: url("' + baseUrl + this.item.wcImageUrl + '");';
        } else {
            return 'background-image: url("' + this.getProductUrl(this.item) + '");';
        }
    }

    getProductUrl(product: OM.ProductUrl){
        let ris = "";
        ris = baseUrl + '/products/images/'
        ris +=  product.sku + '.png';
        return ris;
    }

    select(){
        this.$emit('select', this.item);
    }

    remove(){
        this.$emit('remove', this.item);
    }

    details(){
        this.$emit('details', this.item);
    }

}
