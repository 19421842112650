
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import emailModal from './emailModal.vue';
import { TavolaClient } from '@/services/Services';
import { baseUrl } from '@/config';
import { JsonClone } from '@/utils';

@Options({})
export default class stepModal extends Vue {

    pulisciTavola(){
        store.state.tavola = new OM.Tavola();
        StorageServices.setTavola(store.state.tavola);
        this.$router.push('/table/step0')
        this.$emit('close');
    }

    salvaPerDopo(){
        this.$opModal.show(emailModal, {
            sendCb: (email: string) => {
                return new Promise<void>((resolve, reject) => {
                    TavolaClient.save(store.state.tavola)
                    .then(x => {
                        store.state.tavola.identifier = x;
                        TavolaClient.getLinkTavola(email, store.state.tavola.identifier, store.state.selectedLanguage)
                        .then(x =>{
                            resolve();
                        })
                    })
                })
            } 
        }, () => this.$emit('close'))
    }

    esportaPdf(){
        let tavolaHtml = document.getElementById('tavoloPreview');
        let tavola = <OM.Tavola>JsonClone(store.state.tavola);
        tavola.posto1.products.forEach(x => x.url = this.getProductUrl(x, 1));
        tavola.posto2.products.forEach(x => x.url = this.getProductUrl(x, 2));
        tavola.posto3.products.forEach(x => x.url = this.getProductUrl(x, 3));
        tavola.posto4.products.forEach(x => x.url = this.getProductUrl(x, 4));
        tavola.posto5.products.forEach(x => x.url = this.getProductUrl(x, 5));
        tavola.posto6.products.forEach(x => x.url = this.getProductUrl(x, 6));
        tavola.posto7.products.forEach(x => x.url = this.getProductUrl(x, 7));
        tavola.posto8.products.forEach(x => x.url = this.getProductUrl(x, 8));
        tavola.posto9.products.forEach(x => x.url = this.getProductUrl(x, 9));
        tavola.setsPiatti.forEach(x => x.url = this.getProductUrl(x, 1));
        tavola.setsDecorazioni.forEach(x => x.url = this.getProductUrl(x, 5));
        tavola.extraProducts.forEach(x => x.url = x.wcImageUrl);

        this.$opModal.show(emailModal, {
            sendCb: (email: string) => {
                return new Promise<void>((resolve, reject) => {
                    TavolaClient.save(store.state.tavola)
                    .then(x => {
                        store.state.tavola.identifier = x;
                        tavola.identifier = x;
                        TavolaClient.getPdfTavola({
                            email: email,
                            tavola: tavola,
                            tavolaHtml: tavolaHtml.innerHTML,
                            language: store.state.selectedLanguage
                        })
                        .then(x =>{
                            resolve();
                        })
                    })
                })
            } 
        }, () => this.$emit('close'))
    }
    
    getProductUrl(product: OM.ProductUrl, postoTavola: number){
        let ris = "";
        if(postoTavola < 5){
            ris = baseUrl + '/products/images/'
        } else {
            ris = baseUrl + '/products/images/'
        }
        ris +=  product.sku + '.png';
        return ris;
    }

}
