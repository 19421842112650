import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_posto_tavola = _resolveComponent("posto-tavola")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tavoloPreview", _ctx.$store.state.baseTavolaClass]),
    id: "tavoloPreview"
  }, [
    _createElementVNode("div", {
      class: "baseTavola",
      style: _normalizeStyle(_ctx.$store.state.baseTavolaStyle(_ctx.localTavola))
    }, [
      _createVNode(_component_posto_tavola, {
        class: "posto1",
        showOverlay: _ctx.showOverlay,
        showPlaceholder: _ctx.showPlaceholders,
        postoId: 1,
        modelValue: _ctx.localTavola.posto1,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localTavola.posto1) = $event)),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('postoTavola/1')))
      }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
      _createVNode(_component_posto_tavola, {
        class: "posto2",
        showOverlay: _ctx.showOverlay,
        showPlaceholder: _ctx.showPlaceholders,
        postoId: 2,
        modelValue: _ctx.localTavola.posto2,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localTavola.posto2) = $event)),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('postoTavola/2')))
      }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
      _createVNode(_component_posto_tavola, {
        class: "posto3",
        showOverlay: _ctx.showOverlay,
        showPlaceholder: _ctx.showPlaceholders,
        postoId: 3,
        modelValue: _ctx.localTavola.posto3,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localTavola.posto3) = $event)),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push('postoTavola/3')))
      }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
      _createVNode(_component_posto_tavola, {
        class: "posto4",
        showOverlay: _ctx.showOverlay,
        showPlaceholder: _ctx.showPlaceholders,
        postoId: 4,
        modelValue: _ctx.localTavola.posto4,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localTavola.posto4) = $event)),
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push('postoTavola/4')))
      }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
      (_ctx.showAll)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_posto_tavola, {
              class: "posto5",
              showOverlay: _ctx.showOverlay,
              showPlaceholder: _ctx.showPlaceholders,
              postoId: 5,
              modelValue: _ctx.localTavola.posto5,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localTavola.posto5) = $event)),
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$router.push('postoTavola/5')))
            }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
            _createVNode(_component_posto_tavola, {
              class: "posto6",
              showOverlay: _ctx.showOverlay,
              showPlaceholder: _ctx.showPlaceholders,
              postoId: 6,
              modelValue: _ctx.localTavola.posto6,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localTavola.posto6) = $event)),
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$router.push('postoTavola/6')))
            }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
            _createVNode(_component_posto_tavola, {
              class: "posto7",
              showOverlay: _ctx.showOverlay,
              showPlaceholder: _ctx.showPlaceholders,
              postoId: 7,
              modelValue: _ctx.localTavola.posto7,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localTavola.posto7) = $event)),
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$router.push('postoTavola/7')))
            }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
            _createVNode(_component_posto_tavola, {
              class: "posto8",
              showOverlay: _ctx.showOverlay,
              showPlaceholder: _ctx.showPlaceholders,
              postoId: 8,
              modelValue: _ctx.localTavola.posto8,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localTavola.posto8) = $event)),
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$router.push('postoTavola/8')))
            }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"]),
            _createVNode(_component_posto_tavola, {
              class: "posto9",
              showOverlay: _ctx.showOverlay,
              showPlaceholder: _ctx.showPlaceholders,
              postoId: 9,
              modelValue: _ctx.localTavola.posto9,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localTavola.posto9) = $event)),
              onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.$router.push('postoTavola/9')))
            }, null, 8, ["showOverlay", "showPlaceholder", "modelValue"])
          ], 64))
        : _createCommentVNode("", true)
    ], 4),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.isPreset)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "clickStopper",
          onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (_ctx.$emit('click', $event)), ["stop","prevent"])),
          style: {"z-index":"99"}
        }))
      : _createCommentVNode("", true)
  ], 2))
}