
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import { baseUrl } from '@/config';
import WithModel from '@/mixins/withModel';

@Options({})
export default class tovagliettaEmptier extends mixins(WithModel) {

    empty(){
        this.localValue = {
            sku: '',
            nome: '',
            url: ''
        }
        this.emitModel(this.localValue);
    }

}
