
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop, Watch } from 'vue-property-decorator';
import { baseUrl } from '@/config';
import WithModel from '@/mixins/withModel';
import store from '@/store';

@Options({})
export default class postoTavola extends mixins(WithModel) {

    @Prop() postoId: number;
    @Prop() showPlaceholder: boolean;
    @Prop({
        default: false
    }) showOverlay: boolean;

    @Watch('$store.state.tavola')
    onTavolaChange(next, prev){
        setTimeout(() => {
            this.localValue.id = this.postoId;
        })
    }

    created(){
        this.localValue.id = this.postoId;
    }

    get isEmpty(){
        return !this.hasTovaglietta && this.localValue.products.length == 0 && this.sets.length == 0;
    }

    get hasTovaglietta(){
        return !!this.localValue.tovaglietta.url;
    }

    get sets(){
        if(this.postoId < 5){
            return store.state.tavola.setsPiatti;
        } else if(this.postoId < 9) {
            return store.state.tavola.setsDecorazioni;
        } else {
            return [];
        }
    }

    getTovagliettaUrl(tovaglietta: OM.ProductUrl){
        return baseUrl + tovaglietta.url;
    }

    getProductUrl(product: OM.ProductUrl){
        let ris = "";
        if(this.postoId < 5){
            ris = baseUrl + '/products/images/'
        } else {
            ris = baseUrl + '/products/images/'
        }
        if(product.isSet){
            let _postoId = this.postoId;
            if(this.postoId > 4){
                _postoId -= 4;
            }
            ris +=  product.sku + '_' + _postoId + '.png';
        } else {
            ris +=  product.sku + '.png';
        }
        return ris;
    }


}
