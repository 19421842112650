import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "postoItem tovaglietta"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["postoTavola pointer", { occupied: !_ctx.isEmpty, showPlaceholder: _ctx.showPlaceholder }])
  }, [
    (_ctx.isEmpty)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({ placeholder: _ctx.showPlaceholder })
        }, [
          (_ctx.showPlaceholder)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.localValue.id), 1)
              ], 64))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.hasTovaglietta)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("img", {
                  src: _ctx.getTovagliettaUrl(_ctx.localValue.tovaglietta)
                }, null, 8, _hoisted_2)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue.products, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["postoItem", { 'rotate-90': _ctx.postoId == 9 }]),
              key: item.sku,
              style: _normalizeStyle('z-index: ' + item.zIndex)
            }, [
              _createElementVNode("img", {
                src: _ctx.getProductUrl(item)
              }, null, 8, _hoisted_3)
            ], 6))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sets, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "postoItem",
              key: item.sku,
              style: _normalizeStyle('z-index: ' + item.zIndex)
            }, [
              _createElementVNode("img", {
                src: _ctx.getProductUrl(item)
              }, null, 8, _hoisted_4)
            ], 4))
          }), 128)),
          (_ctx.showOverlay)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (!_ctx.$store.state.isMobile)
                  ? (_openBlock(), _createBlock(_component_localized_text, {
                      key: 0,
                      localizedKey: "Clicca per modificare",
                      text: "Clicca per modificare"
                    }))
                  : (_openBlock(), _createBlock(_component_localized_text, {
                      key: 1,
                      localizedKey: "Fai tap per modificare",
                      text: "Fai \"tap\" per modificare"
                    }))
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ], 2))
}