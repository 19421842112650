
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import moment from 'moment';
import { BaseTavolaClient } from '@/services/Services';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import stepModal from '@/components/modals/stepModal.vue';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class TavoloPreview extends Vue {

    @Prop({
        default: true
    }) showAll: boolean;
    
    @Prop() isPreset: boolean;

    @Prop({
        default: true
    }) showPlaceholders: boolean;
    @Prop({
        default: false
    }) showOverlay: boolean;

    @Prop() tavola: OM.Tavola;

    @Watch('tavola')
    onTavolaChange(next, prev){
        this.init();
    }
    @Watch('$store.state.tavola')
    onStoreTavolaChange(next, prev){
        this.init();
    }

    localTavola: OM.Tavola = null;

    created(){
        this.init();
    }

    mounted(){
        setTimeout(() => {
            store.state.calcMisureTavola();
        }, 0)
    }

    init(){
        if(this.tavola){
            this.localTavola = this.tavola;
        } else {
            this.localTavola = store.state.tavola;
        }
    }

}
