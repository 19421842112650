
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';
import store from '@/store';

@Options({})
export default class copiaPostoSelector extends mixins(WithModel) {

    @Prop() copyFrom: OM.PostoTavola[];

    showAll: boolean = false;
    bodyEl: any;

    mounted(){
        this.bodyEl = this.$refs.body;
    }

    doScroll(amount: number){
        this.bodyEl.scrollLeft = this.bodyEl.scrollLeft + amount;
    }

    onSelect(item: OM.PostoTavola){
        this.$emit('select', item)
    }

}
