import router from "./router";
import * as OM from "./Model";
import store from "./store";

export function JsonClone<T>(obj: T){
    return <T>JSON.parse(JSON.stringify(obj));
}

export function insertAllLanguages(localized: OM.LocalizedValue){
    if(!localized)
        localized = new OM.LocalizedValue();
        
    var enabledLanguages = store.state.consts.enabledLanguages;

    enabledLanguages.forEach( lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find( x => x == lang);
        if(!found){
            localized.values[lang] = "";
        }
    })
}

export function getSkus(){
    let ris: string[] = [];
    ris.push(...extractSkus(store.state.tavola.posto1.products));
    ris.push(...extractSkus(store.state.tavola.posto2.products));
    ris.push(...extractSkus(store.state.tavola.posto3.products));
    ris.push(...extractSkus(store.state.tavola.posto4.products));
    ris.push(...extractSkus(store.state.tavola.posto5.products));
    ris.push(...extractSkus(store.state.tavola.posto6.products));
    ris.push(...extractSkus(store.state.tavola.posto7.products));
    ris.push(...extractSkus(store.state.tavola.posto8.products));
    ris.push(...extractSkus(store.state.tavola.posto9.products));
    ris.push(...extractSkus(store.state.tavola.setsDecorazioni));
    ris.push(...extractSkus(store.state.tavola.setsPiatti));
    return ris.filter((x, i, arr) => arr.indexOf(x) == i);
}
function extractSkus(products: OM.ProductUrl[]) {
    return products
        .map(x => x.sku)
        .filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
}

export function getCollezioni(){
    let ris: string[] = [];
    ris.push(...extractCollezioni(store.state.tavola.posto1.products));
    ris.push(...extractCollezioni(store.state.tavola.posto2.products));
    ris.push(...extractCollezioni(store.state.tavola.posto3.products));
    ris.push(...extractCollezioni(store.state.tavola.posto4.products));
    ris.push(...extractCollezioni(store.state.tavola.posto5.products));
    ris.push(...extractCollezioni(store.state.tavola.posto6.products));
    ris.push(...extractCollezioni(store.state.tavola.posto7.products));
    ris.push(...extractCollezioni(store.state.tavola.posto8.products));
    ris.push(...extractCollezioni(store.state.tavola.posto9.products));
    ris.push(...extractCollezioni(store.state.tavola.setsDecorazioni));
    ris.push(...extractCollezioni(store.state.tavola.setsPiatti));
    return ris.filter((x, i, arr) => arr.indexOf(x) == i);
}
function extractCollezioni(products: OM.ProductUrl[]) {
    return products
        .map(x => x.collezione)
        .filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
}

export function download(filename, text, type) {
    let blob = new Blob([text], { type })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click();
}

export function getUniversalISOStringDate(input: Date): any {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

    return data.toISOString();
}

export function getUniversalDate(input: any): Date {
    var data = new Date(input);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());

    return data;
}

export function checkLocalizedValue(val: OM.LocalizedValue){
    return Object.values(val.values).every(x => !!x);
}

export function underscore(value: string){
    let ris = value;
    ris = ris.replaceAll(", ", "_");
    ris = ris.replaceAll(" ", "_");
    ris = ris.replaceAll("-", "_");
    ris = ris.replaceAll(",", "_");
    ris = ris.replaceAll("'", "_");
    ris = ris.replaceAll(".", "");
    return ris.trim();
}