
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';

@Options({})
export default class confirmNavigationModal extends Vue {

    @Prop() okCb: () => void

    ok(){
        this.okCb();
        this.$emit('close');
    }

}
