import * as OM from '@/Model';
import { IModalPlugin } from '@/plugins/modalPlugin/modalPlugin';
import AlertModal from '@/components/modals/alertModal.vue';
import confirmNavigationModal from '@/components/modals/confirmNavigationModal.vue';
import InputModal from '@/components/modals/inputModal.vue';
import stepModal from '@/components/modals/stepModal.vue'

class _ModalServices {
    
    $opModal: any;
    setOpModal(input: IModalPlugin){
        this.$opModal = input;
    }

    openStepModal(){
        this.$opModal.show(stepModal, {});
    }

    alertModal(title: string, text: string, okText?: string, okCallback?: () => void ){
        okText = okText || "OK";
        this.$opModal.show(AlertModal, {
            title,
            text,
            okText,
            okCallback
        })
    }

    confirmNavigationModal(okCallback: () => void, closeCallback: () => void){
        this.$opModal.show(confirmNavigationModal, {
            okCb: okCallback
        }, closeCallback)
    }

    inputModal(title: string, text: string, okText: string, koText: string, value: string, placeholder: string, okCallback: (input: string) => void, koCallback?: () => void ){
        okText = okText || "OK";
        koText = koText || "Annulla";
        placeholder = placeholder || "Scrivi qui";
        this.$opModal.show(InputModal, {
            title,
            text,
            okText,
            koText,
            value,
            placeholder,
            okCallback,
            koCallback
        })
    }

}

export let ModalServices = new _ModalServices();