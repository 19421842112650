import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import './utils';

import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/css/style.css';

import * as DIR from '@/directives';
import FTS from '@/filters';

import modalPlugin from './plugins/modalPlugin';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin)
    .directive('date', DIR.date)
    .directive("localizedPlaceholder", DIR.localizedPlaceholderDirective);
app.config.globalProperties.$filters = FTS;

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
       $filters: any
    }
}

import MiniSpinner from '@/components/miniSpinner.vue';
app.component('MiniSpinner', MiniSpinner);
import baseTavola from '@/components/baseTavola.vue';
app.component('baseTavola', baseTavola);
import tovaglietta from '@/components/tovaglietta.vue';
app.component('tovaglietta', tovaglietta);
import tovagliettaEmptier from '@/components/tovagliettaEmptier.vue';
app.component('tovagliettaEmptier', tovagliettaEmptier);
import postoTavola from '@/components/postoTavola.vue';
app.component('postoTavola', postoTavola);
import cartResume from '@/components/cartResume.vue';
app.component('cartResume', cartResume);
import productSelector from '@/components/productSelector.vue';
app.component('productSelector', productSelector);
import copiaPostoSelector from '@/components/copiaPostoSelector.vue';
app.component('copiaPostoSelector', copiaPostoSelector);
import productUrl from '@/components/productUrl.vue';
app.component('productUrl', productUrl);
import tavoloPreview from '@/components/tavoloPreview.vue';
app.component('tavoloPreview', tavoloPreview);
import sidePage from '@/components/sidePage.vue';
app.component('sidePage', sidePage);

import localizedText from '@/components/localizedText.vue';
app.component('localizedText', localizedText);
import formField from '@/components/formField.vue';
app.component('formField', formField);
import localizedFormField from '@/components/localizedFormField.vue';
app.component('localizedFormField', localizedFormField);


app.mount('#app');
