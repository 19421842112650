import { ModalServices } from '@/services/ModalServices';
import store from '@/store';
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: { },
        component: () => import("@/views/home.vue")
    },
    {
        path: '/table/step0',
        name: 'step0',
        meta: { },
        component: () => import("@/views/tavola/step0.vue"),
    },
    {
        path: '/table/postoTavola/:posizione',
        name: 'postoTavola',
        meta: { },
        component: () => import("@/views/tavola/postoTavolaDetail.vue"),
    },
    {
        path: '/table/step2',
        name: 'step2',
        meta: { },
        component: () => import("@/views/tavola/step2.vue"),
    },
    {
        path: '/table/riepilogo',
        name: 'riepilogo',
        meta: { },
        component: () => import("@/views/tavola/riepilogo.vue"),
    },
    {
        path: '/table/suggeriti',
        name: 'suggeriti',
        meta: { },
        component: () => import("@/views/tavola/suggeriti.vue"),
    },
    {
        path: '/table/productDetail/:sku',
        name: 'productDetail',
        meta: { },
        component: () => import("@/views/tavola/productDetail.vue"),
    },
    {
        path: '/presets/',
        name: 'presets',
        meta: { },
        component: () => import("@/views/tavola/presets.vue"),
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
    }
})


function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
    if(from.name == 'step0' || from.name == 'riepilogo'){
        if(to.name == 'presets' || to.name == 'home'){
            new Promise((resolve, reject) => {
                let isValid = false;
                ModalServices.confirmNavigationModal(() => {
                    isValid = true;
                }, () => {
                    if(!isValid){
                        next(false);
                    } else {
                        next(true);
                    }
                })
            })
        } else {
            next();
        }
    } else {
        store.state.isLabelinaOpen = false;
        next();
    }
    // if(!hasQueryParams(to) && hasQueryParams(from)){
    //     next({name: to.name, params: to.params, query: from.query});
    // } else {
    //     next();
    // }
})

router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})


export default router
