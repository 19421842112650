import * as OM from '@/Model';

class _StorageServices {
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        try {
            if(data){
                return JSON.parse(data);
            } else {
                return null;
            }
        } catch(ex){
            return null;
        }
    }

    setCustomerId(id: string){
        window.localStorage.setItem('customerId', id);
    }
    getCustomerId(){
        return this.readFromLocalStorage<number>('customerId');
    }

    setCartHash(id: string){
        window.localStorage.setItem('CartHash', id);
    }
    getCartHash(){
        return this.readFromLocalStorage<number>('CartHash');
    }

    setTavola(tavola: OM.Tavola){
        window.localStorage.setItem('tavola', JSON.stringify(tavola));
    }
    getTavola(){
        return this.readFromLocalStorage<OM.Tavola>('tavola');
    }

    setLoginToken(token: string){
        window.localStorage.setItem('authtoken', token);
    }
    getLoginToken(){
        return this.readFromLocalStorage<string>('authtoken');
    }

    SetLanguage(language: string) {
        window.localStorage.setItem('locale', language);
    }
    GetLanguage() {
        let data = window.localStorage.getItem('locale');
        if(!data){
            return '';
        } else {
            return data;
        }
    }

}

export let StorageServices = new _StorageServices();