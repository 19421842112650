import { createStore } from 'vuex'
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import { UserClient } from '@/services/Services';
import { baseUrl } from '@/config';
import { getCollezioni, getSkus } from '@/utils';
import { ModalServices } from '@/services/ModalServices';

let _store = {
    state: {
        showSpinner: 0,
        selectedLanguage: "",
        setLanguage: (val) => {
            store.state.selectedLanguage = val;
            StorageServices.SetLanguage(val);
        },
        privacyAccepted: false,
        tavolaIdentifier: '',
        isMobile: false,
        isTouchScreen: false,
        consts: {
            localizedValues: [],
            enabledLanguages: [],
        },
        scrollCb: (ev) => {},
        tavola: <OM.Tavola>new OM.Tavola(),
        baseTavolaStyle: (tavola?: OM.Tavola) => {
            if(tavola){
                return "background-image: url('" + baseUrl + '/' + tavola.fondoTavola.url + "');"
            } else {
                return "background-image: url('" + baseUrl + '/' + store.state.tavola.fondoTavola.url + "');"
            }
        },
        baseTavolaClass: '',
        calcMisureTavola: () => {
            let tavoloPreview = document.querySelector('#tavoloPreview');
            if(!tavoloPreview)
                return;
            let rect = tavoloPreview.getBoundingClientRect();
            if(rect.width > rect.height){
                store.state.baseTavolaClass = 'vertical';
                setTimeout(() => {
                    let baseTavola = document.querySelector('.tavolo .tavoloPreview .baseTavola');
                    let rekt = baseTavola.getBoundingClientRect();
                    let newW = 550 * rekt.height / 592;
                    (<HTMLElement>baseTavola).style.width = newW + "px";
                }, 50);
            } else {
                store.state.baseTavolaClass = 'horizontal';
                setTimeout(() => {
                    let baseTavola = document.querySelector('.tavolo .tavoloPreview .baseTavola');
                    (<HTMLElement>baseTavola).style.width = "";
                }, 50);
            }
        },
        stepConfigs: <OM.StepConfig[]>[],
        getCollezioni: () => {
            return getCollezioni();
        },
        getSkus: () => {
            return getSkus();
        },
        sidePageEl: <HTMLElement>null,
        isLabelinaOpen: false,
        openStepModal: () => {
            ModalServices.openStepModal();
        },
        afterNavCbs: []
    }
}

_store.state.isMobile = window.innerWidth <= 540;
window.addEventListener('resize', () => {
    store.state.isMobile = window.innerWidth <= 540;
    store.state.isTouchScreen = isTouchScreendevice();
    store.state.calcMisureTavola();
})

function isTouchScreendevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints >= 1;      
};

_store.state.selectedLanguage = StorageServices.GetLanguage();
let tavola = StorageServices.getTavola();
if(tavola){
    _store.state.tavola = tavola;
}

let store = createStore(_store);
export default store;