import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any) {
  const _component_spinner_container = _resolveComponent("spinner-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modals_container = _resolveComponent("modals-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_spinner_container),
          _createVNode(_component_router_view),
          _createVNode(_component_modals_container)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}