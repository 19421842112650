import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-2 mt-1 text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: "baseTavolaItem baseTavola pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitModel(_ctx.item)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["baseTavolaColor", { selected: _ctx.localValue == _ctx.item }]),
      style: _normalizeStyle(_ctx.imageurl)
    }, null, 6),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        class: "text-gray",
        localizedKey: _ctx.item.nome,
        text: _ctx.item.nome
      }, null, 8, ["localizedKey", "text"])
    ])
  ]))
}